<template>
      <div class="floorplans-page">
            <Floorplans />
      </div>
</template>

<script>
import Floorplans from "@/components/pages/floorplans/Floorplans";
export default {
      name: "floorplans-page",
      components: { Floorplans },
};
</script>

<style lang='scss' scoped>
$opacity: 5%;
$title-opacity: 10%;
$serach-input-border: #131313;
</style>