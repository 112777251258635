<template>
      <div class="floorplans">
            <Header/>
            <SingleFloorXL/>
      </div>
</template>

<script>
      import Header from './FloorplansHeader.vue';

      export default {
            name: 'floorplans',
            components: {
                  Header,
                  SingleFloorXL: () => import("./SingleFloorXL.vue"),
            },
            methods:{
                  clickItem(){
                        console.log(123123123)
                  }
            }
      }
</script>

<style lang='scss'>
@import 'Floorplans';
</style>