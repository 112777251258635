<template>
      <div class="floorplans-header row sm:align-items-center">
            <div class="col-12 d-none sm:d-flex sm:col-4 md:col-6 md:justify-content-start justify-content-center pb-0">
                  <span class="page-title">Floorplans</span>
            </div>
            <div class="input-box col-8 sm:col-5 md:col-6 sm:d-flex sm:align-items-center sm:justify-content-end">
                  <div class="col-6 mr-20">
                        <Input @input="filterItems" bgColor="#00000014" icon="search" placeholder="Search by unit name"/>
                  </div>
                  <div class="filter-box col-3 col-md-2">
                        <Btn class="filters-btn text-center full-width btn-sm" @click="showFilters = true;" variant="black" icon="filter" title="Filters"/>
                        <main v-show="showFilters" class="filter-wrapper">
                              <section class="filters_header">
                                    <div class="d-flex align-items-center">
                                          <img src="@/assets/icons/svg/filter.svg" alt="filter">
                                          <span class="ml-15">Filters</span>
                                    </div>
                                    <img class="cursor-pointer" @click="showFilters = false" src="@/assets/icons/svg/close.svg" alt="close">
                              </section>
                              <section class="bedrooms">
                                    <div class="d-flex align-items-flex-start flex-column">
                                          <p class="mb-10">
                                                <img src="@/assets/icons/svg/room.svg" alt="room">
                                                <span class="ml-15">Bedrooms</span>
                                          </p>
                                          <div class="row justify-content-flex-start full-width">
                                                <div v-for="(item, index) in bedrooms" :key="index" @click="handleBedSelection(item)" :class="{'active': filters.bedrooms.includes(item)}" class="d-flex justify-center col-4">
                                                      <div class="number-of-rooms">
                                                            <span>{{ item }}</span>
                                                      </div>
                                                </div>
                                          </div>
                                    </div>
                              </section>
                              <section class="bathrooms">
                                    <div class="d-flex align-items-flex-start flex-column">
                                          <p class="mb-10">
                                                <img src="@/assets/icons/svg/shower.svg" alt="shower">
                                                <span class="ml-15">Bathrooms</span>
                                          </p>
                                          <div class="row justify-content-flex-start full-width">
                                                <div v-for="item in bathrooms" :key="`bath-${item}`" @click="handleClick(item)" :class="{'active': filters.bathrooms.includes(String(item))}" class="number-of-rooms d-flex justify-center col-3">
                                                      <span>{{ item }}</span>
                                                </div>
                                          </div>
                                    </div>

                              </section>
                              <section class="size">
                                    <div class="d-flex justify-content-between">
                                          <div class="d-flex align-items-center mb-20">
                                                <img src="@/assets/icons/svg/resize.svg" alt="resize">
                                                <span class="ml-15">Size</span>
                                          </div>
                                          <span class="details">{{min ?? minVal}} - {{max || maxVal}} SQFT</span>
                                    </div>
                                    <range-slider v-if="minVal && maxVal" @mouseUp="onMouseUp" ref="slider" @handleValue="handleValue" :step="10" :min="minVal" :max="maxVal"></range-slider>
                              </section>
                              <section class="footer">
                                    <span @click="resetFilters()">Reset Filters</span>
                              </section>
                        </main>
                  </div>
            </div>
            <!-- <div class="filter-box pos-relative col-4 sm:col-3 md:col-2 d-flex justify-content-end xsm:justify-content-center sm:d-flex sm:align-items-center sm:justify-content-center lg:justify-content-end"> -->
            <!-- </div> -->
      </div>
</template>

<script>
import Btn from '@b/button/Button';
import Input from '@b/input/Input';
import RangeSlider from '@b/range-slider/RangeSlider';
import { bus } from '@m'
import { getFilters } from "@api/axios/unit.service";

export default {
      name: 'floorplans-header',
      components: {Btn, Input, RangeSlider},
      data() {
            return {
                  showFilters: false,
                  filters: {
                        bedrooms: [],
                        bathrooms: [],
                  },

                  // Range Slider States
                  minVal: null,
                  maxVal: null,
                  min: this.minVal,
                  max: this.maxVal,

                  //states
                  bedrooms : [],
                  bathrooms : []
            }
      },
      methods: {
            passFilterData(shouldReset){
                  if(shouldReset) bus.$emit('reset') 
                  else bus.$emit('filter', {...this.filters, min: this.min ?? this.minVal, max: this.max?? this.maxVal});
            },
            handleClick(i) {
                  this.filters.bathrooms.indexOf(String(i)) === -1 ? this.filters.bathrooms.push(String(i)) : this.filters.bathrooms.splice(this.filters.bathrooms.indexOf(String(i)), 1);
                  this.passFilterData()
            },
            handleValue(options){
                  this[options.type] = options.value;
                  if(options.isDragging) return
                  this.passFilterData()
            },
            handleBedSelection(i){
                  console.log(i)
                  this.filters.bedrooms.indexOf(i) === -1 ? this.filters.bedrooms.push(i) : this.filters.bedrooms.splice(this.filters.bedrooms.indexOf(i), 1);
                  // this.passFilterData()
                  this.passFilterData()
            },
            onMouseUp(){
                  this.passFilterData();
            },
            resetFilters(){
                  this.filters.bedrooms = [];
                  this.filters.bathrooms = [];
                  this.min = this.minVal;
                  this.max = this.maxVal;
                  this.$refs.slider.$refs.track1.style.left = "0%";
                  this.$refs.slider.$refs.track2.style.left = "100%";
                  this.$refs.slider.minValue = this.minVal;
                  this.$refs.slider.maxValue = this.maxVal;
                  this.$refs.slider.setTrackHightlight();
                  this.passFilterData(true);
            },
            filterItems(val){
                  bus.$emit('filterItemsByName', val.toUpperCase())
            },
            getFilters(){
                  getFilters().then(res => {
                        this.bathrooms = res.data.bath;
                        res.data.bed.unshift(res.data.bed[res.data.bed.length - 1]);
                        this.bedrooms = res.data.bed;
                        this.bedrooms.pop();
                        this.minVal = res.data.size.min;
                        this.maxVal = res.data.size.max;
                  }).catch(e => {
                        console.log(e)
                  })
            }
      },
      mounted(){
            this.getFilters();
            // document.addEventListener('click', function(e){
            //       if(e.target.classList.contains('input__has__icon')) {
            //             document.querySelector('.input__icon').style.display = "none"
            //       } else {
            //             document.querySelector('.input__icon').style.display = "block"
            //       }
            // })
      },
      beforeDestroy(){
            // document.removeEventListener('click', function(e){
            //       if(e.target.classList.contains('input__has__icon')) {
            //             document.querySelector('.input__icon').style.display = "none"
            //       } else {
            //             document.querySelector('.input__icon').style.display = "block"
            //       }
            // })
      }
}
</script>

<style lang='scss'>
@import 'FloorplansHeader';
</style>